import React from "react";
import styled from "styled-components";


export default function GhostClient() {
  return (
    <Root>
        <h1>ghost</h1>
    </Root>
  )
}
const Root = styled.section`

`